import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const createNewVersion = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "/gameversions", data);
};

const getListVersions = () => {
  return AxiosInstance.get(Config.apiUrl + "/gameversions");
};

const getGameVersion = (id: string | undefined) => {
  return AxiosInstance.get(Config.apiUrl + "/gameversions/" + id);
};

const updateGameVersion = (data: any, id: string | undefined) => {
  return AxiosInstance.put(Config.apiUrl + "/gameversions/" + id, data);
};

// SCHEDULE
const getViewSchedule = () => {
  return AxiosInstance.get(Config.apiUrl + "/gamemaintenance");
};

const createNewSchedule = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "/gamemaintenance", data);
};

const cancelSchedule = (id: string) => {
  return AxiosInstance.put(Config.apiUrl + `/gamemaintenance/cancel/${id}`);
};

const goLive = (id: string) => {
  return AxiosInstance.put(Config.apiUrl + `/gamemaintenance/golive/${id}`);
};

const VersionAndMaintenanceService = {
  createNewVersion,
  getListVersions,
  getGameVersion,
  updateGameVersion,
  getViewSchedule,
  createNewSchedule,
  cancelSchedule,
  goLive
};

export default VersionAndMaintenanceService;
