import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const Login = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "/login", data);
};

const AuthServices = {
  Login,
};

export default AuthServices;
