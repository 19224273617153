import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GameModeService from "../../../services/GameMode.services";
import { createNewVersionSchema } from "../../../utils/schemaValidation";
import "./styles.css";
import VersionAndMaintenanceService from "../../../services/Version&Maintenance.services";
type FormData = {
  appVersion: string;
  deviceType: string;
  state: string;
  maintenanceState: string;
  isUpdateRequired: boolean;
  isInMaintainance: boolean;
};

const defaultValue = {
  appVersion: "",
  deviceType: "",
  state: "",
  maintenanceState: "",
  isUpdateRequired: false,
  isInMaintainance: false,
};

const EditNewVersion = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: yupResolver(createNewVersionSchema),
    defaultValues: defaultValue,
  });

  const getGameMode = () => {
    setLoading(true);
    VersionAndMaintenanceService.getGameVersion(id).then(
      (res: any) => {
        if (res) {
          reset(res);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getGameMode();
  }, []);

  const onSubmit = (data: FormData) => {
    setLoading(true);

    VersionAndMaintenanceService.updateGameVersion(data, id).then(
      (res: any) => {
        if (res._id) {
          toast.success("Update Game Version Successfully");
          setLoading(false);
          Navigate("/listVersions");
        }
      },
      (err) => {
        toast.error("Created Game Version Fail");
      }
    );
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="Update Game Version" />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">Update Game Version</h3>
              <div className="body-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-12 form-group">
                      <label htmlFor="version" className="form-control-label">
                        Version
                      </label>
                      <input
                        id="version"
                        type="text"
                        placeholder="Version"
                        className="form-control"
                        {...register("appVersion")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="appVersion"
                        render={({ message }) => (
                          <p className="text-danger fs-3 mt-2">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-12 form-group">
                      <label
                        htmlFor="deviceType"
                        className="form-control-label"
                      >
                        Device Type
                      </label>
                      <select
                        id="deviceType"
                        className="form-control"
                        {...register("deviceType")}
                      >
                        <option value="" hidden>
                          -Device Type-
                        </option>
                        <option value="androidApp">Android</option>
                        <option value="iosApp">Ios</option>
                        <option value="mac">Mac</option>
                        <option value="browser">Browser</option>
                        <option value="windows">Windows</option>
                        <option value="website">Website</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="deviceType"
                        render={({ message }) => (
                          <p className="text-danger fs-3 mt-2">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-12 form-group">
                      <label htmlFor="status" className="form-control-label">
                        Status
                      </label>
                      <select
                        id="status"
                        className="form-control"
                        {...register("state")}
                      >
                        <option value="" hidden>
                          -Status-
                        </option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="state"
                        render={({ message }) => (
                          <p className="text-danger fs-3 mt-2">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-12 form-group">
                      <label
                        htmlFor="maintenanceStatus"
                        className="form-control-label"
                      >
                        Maintenance Status
                      </label>
                      <select
                        id="maintenanceStatus"
                        className="form-control"
                        {...register("maintenanceState")}
                      >
                        <option value="" hidden>
                          -Maintenance Status-
                        </option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="maintenanceState"
                        render={({ message }) => (
                          <p className="text-danger fs-3 mt-2">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="btn_group mt-5 d-flex justify-content-center align-items-center pb-5">
                    <button
                      type="button"
                      className="btn-custom btn-secondary mx-2"
                      onClick={() => Navigate("/listVersions")}
                    >
                      Back
                    </button>

                    <button type="submit" className="btn-custom btn-submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};
export default EditNewVersion;
