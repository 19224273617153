import { Col, Container, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VersionAndMaintenanceService from "../../../services/Version&Maintenance.services";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { MdOutlineCancel } from "react-icons/md";

type Props = {};
const PER_PAGE = 5;

const ViewSchedule = (props: Props) => {
  const Navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [listSchedule, setListSchedule] = useState([]);

  const handlePageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const getListSchedule = () => {
    setLoading(true);
    VersionAndMaintenanceService.getViewSchedule().then((res: any) => {
      if (res?.length > 0) {
        setListSchedule(res);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getListSchedule();
  }, []);

  const handleCancel = (id: string) => {
    Swal.fire({
      title: "Do you want to cancel?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed) {
        VersionAndMaintenanceService.cancelSchedule(id)
          .then(
            (res: any) => {
              if (res.errors.length > 0) {
                toast.error(res.errors[0].message);
                return;
              }
              toast.success("Cancel Schedule Successfully");
            },
            (err) => {
              toast.error("Cancel Schedule Fail");
            }
          )
          .finally(() => {
            getListSchedule();
          });
      }
    });
  };

  const handleGoLive = (id: string) => {
    Swal.fire({
      title: "Do you want to make Golive?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed) {
        VersionAndMaintenanceService.goLive(id)
          .then(
            (res: any) => {
              if (res.errors.length > 0) {
                toast.error(res.errors[0].message);
                return;
              }
              toast.success("Cancel Schedule Successfully");
            },
            (err) => {
              toast.error("Cancel Schedule Fail");
            }
          )
          .finally(() => {
            getListSchedule();
          });
      }
    });
  };

  const totalPage = Math.ceil(listSchedule?.length / PER_PAGE);
  const currentPage = pageNumber * PER_PAGE;
  const displayData = listSchedule
    ?.slice(currentPage, currentPage + PER_PAGE)
    ?.map((item: any, index: any) => {
      return (
        <tr key={item._id}>
          <td className="hidden-name-table">
            {moment(item.scheduledServerDowntime).format(
              "MMM D, YYYY h:mm:ss A"
            )}
          </td>
          <td className="hidden-name-table">
            {moment(item.scheduledServerUptime).format("MMM D, YYYY h:mm:ss A")}
          </td>
          <td className="hidden-name-table">
            {item.maintenanceStatus.toUpperCase()}
          </td>
          <td>
            {item.maintenanceStatus !== "canceled" && (
              <button
                type="button"
                className="btn-custom btn-warning mx-1"
                onClick={() => handleCancel(item?.gameMaintenanceId)}
              >
                <MdOutlineCancel />
                Cancel
              </button>
            )}
            {item.maintenanceStatus == "inprogress" && (
              <button
                type="button"
                className="btn-custom btn-warning mx-1"
                onClick={() => handleGoLive(item?.gameMaintenanceId)}
              >
                <MdOutlineCancel />
                GoLive
              </button>
            )}
          </td>
        </tr>
      );
    });
  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="list-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="View Scheduled Maintenance" />
            <div className="card m-auto">
              <div className="portlet-title">
                <h3 className="portlet-title__text">
                  View Scheduled Maintenance
                </h3>
              </div>
              <div className="action mt-2">
                <button
                  className="btn-custom btn-info mx-1"
                  onClick={() => Navigate("/createSchedule")}
                >
                  New +
                </button>
              </div>
              <div className="table-data overflow-scroll">
                <table className="table table-bordered mt-5">
                  <thead>
                    <tr>
                      <th>Server Downtime</th>
                      <th>Server Uptime</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{displayData}</tbody>
                </table>
              </div>
            </div>
          </Col>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageChange}
            activeClassName={"page-item active"}
            disabledClassName={"page-item disabled"}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
          />
        </div>
      )}
    </Container>
  );
};

export default ViewSchedule;
