import * as yup from "yup";
export const createGameModeSchema = yup.object({
  name: yup.string().required("This field is required!"),
  isRankedGame: yup.string().required("This field is required!"),
  supplyRequired: yup.number().when("isRankedGame", {
    is: "Yes",
    then: yup
      .number()
      .min(1, "Value must be greater 1")
      .required("This field is required!")
      .typeError("Value must be a number"),
    otherwise: yup.number().nullable(),
  }),
  unlockLevel: yup
    .number()
    .min(0, "Value must be greater 0")
    .required("This field is required!")
    .typeError("Value must be a number"),
  isGuest: yup.boolean().required("This field is required!"),
});
export const createNewVersionSchema = yup.object({
  appVersion: yup.string().required("This field is required!"),
  deviceType: yup.string().required("This field is required!"),
  state: yup.string().required("This field is required!"),
  maintenanceState: yup.string().required("This field is required!"),
});
