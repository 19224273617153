import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AuthLayout from "./layout/AuthLayout/AuthLayout";
import DashboardLayout from "./layout/DashboardLayout/DashboardLayout";
import CreateNewVersion from "./pages/Version&Maintenance/createNewVersion/CreateNewVersion";
import EditNewVersion from "./pages/Version&Maintenance/editNewVersion/EditNewVersion";
import Login from "./pages/login/Login";
import ListVersions from "./pages/Version&Maintenance/listVersion/ListVersions";
import ViewSchedule from "./pages/Version&Maintenance/ViewSchedule/ViewSchedule";
import CreateSchedule from "./pages/Version&Maintenance/CreateSchedule/CreateSchedule";

function App() {
  const token = localStorage.getItem("access_token");
  return (
    <BrowserRouter>
      <Routes>
        {/* LAYOUT DASHBOARD */}
        {/* <Route path="*" element={token ? <DashboardLayout /> : <Login />} /> */}
        {token ? (
          <Route path="/" element={<DashboardLayout />}>
            <Route path="*" element={<Navigate to="/" />} />

            <Route path="/createNewVersion" element={<CreateNewVersion />} />
            <Route path="/editNewVersion/:id" element={<EditNewVersion />} />
            <Route path="/listVersions" element={<ListVersions />} />
            <Route path="/viewSchedule" element={<ViewSchedule />} />
            <Route path="/createSchedule" element={<CreateSchedule />} />
          </Route>
        ) : (
          <Route path="/" element={<AuthLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route index element={<Login />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
