import { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GameModeService from "../../../services/GameMode.services";
import ReactDatePicker from "react-datepicker";
import VersionAndMaintenanceService from "../../../services/Version&Maintenance.services";

type Props = {};

type formScheduleType = {
  downtime: Date | null;
  uptime: Date | null;
};

const CreateSchedule = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [scheduledServerDowntime, setScheduledServerDowntime] =
    useState<Date | null>();
  const [scheduledServerUptime, setScheduledServerUptime] =
    useState<Date | null>();
  const Navigate = useNavigate();

  const onSubmit = (e: any) => {
    e.preventDefault();

    const dataPost = {
      scheduledServerDowntime: Date.parse(String(scheduledServerDowntime)),
      scheduledServerUptime: Date.parse(String(scheduledServerUptime)),
    };

    createNewSchedule(dataPost);
  };

  const createNewSchedule = (data: any) => {
    setLoading(true);
    VersionAndMaintenanceService.createNewSchedule(data)
      .then(
        (res: any) => {
          if (res.errors.length > 0) {
            toast.error(res.errors[0].message);
            return;
          }
          toast.success("Created New Version Successfully");
          Navigate("/viewSchedule");
        },
        (err) => {
          toast.error("Created New Version Fail");
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="create-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="Create Schedule Maintenance" />
            <div className="card m-auto text-center">
              <h3 className="page-title text-center">
                Create Schedule Maintenance
              </h3>
              <div className="body-form">
                <form onSubmit={onSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="ServerDowntime"
                        className="form-control-label"
                      >
                        Server Downtime
                      </label>
                      <ReactDatePicker
                        className="form-control"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        selected={scheduledServerDowntime}
                        onChange={(date) => setScheduledServerDowntime(date)}
                        placeholderText="Server Downtime"
                        timeIntervals={15}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="serverUptime"
                        className="form-control-label"
                      >
                        Server Uptime
                      </label>
                      <ReactDatePicker
                        className="form-control"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        selected={scheduledServerUptime}
                        onChange={(date) => setScheduledServerUptime(date)}
                        placeholderText="Server Uptime"
                        timeIntervals={15}
                      />
                    </div>
                  </div>

                  <div className="btn_group mt-5 d-flex justify-content-center align-items-center pb-5">
                    <button
                      type="button"
                      className="btn-custom btn-secondary mx-2"
                      onClick={() => Navigate("/viewSchedule")}
                    >
                      Back
                    </button>
                    {loading ? (
                      <span className="loader"></span>
                    ) : (
                      <button
                        type="submit"
                        className="btn-custom btn-submit"
                        disabled={loading}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </div>
      )}
    </Container>
  );
};

export default CreateSchedule;
