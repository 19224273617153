import { useEffect, useState } from "react";
import { Col, Container, Modal } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "../../../components/loading/Loading";
import GameModeService from "../../../services/GameMode.services";
import "./styles.css";
import VersionAndMaintenanceService from "../../../services/Version&Maintenance.services";
const PER_PAGE = 5;
const ListVersions = () => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [listVerions, setListVersions] = useState([]);
  const [idDelete, setIdDelete] = useState();
  const handleClose = () => setShow(false);

  const getListVersions = () => {
    setLoading(true);
    VersionAndMaintenanceService.getListVersions().then((res: any) => {
      if (res.length > 0) {
        setListVersions(res);
      }
      setLoading(false);
    });
  };

  // const handleDelete = () => {
  //   setLoading(true);
  //   GameModeService.deleteGameMode(idDelete)
  //     .then((res) => {
  //       if (res) {
  //         getGameModes();
  //         toast.success("Deleted Successfully!");
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("Deleted Failed!");
  //     });
  //   setShow(false);
  // };

  useEffect(() => {
    getListVersions();
  }, []);

  const handlePageChange = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const totalPage = Math.ceil(listVerions?.length / PER_PAGE);
  const currentPage = pageNumber * PER_PAGE;
  const displayData = listVerions
    ?.slice(currentPage, currentPage + PER_PAGE)
    ?.map((item: any, index: any) => {
      return (
        <tr key={item._id}>
          <td className="hidden-name-table">{item.appVersion}</td>
          <td className="hidden-name-table">{item.deviceType}</td>
          <td className="hidden-name-table">{item.state}</td>
          <td className="hidden-name-table">{item.maintenanceState}</td>
          <td>
            <button
              type="button"
              className="btn-custom btn-info mx-1"
              onClick={() => Navigate(`/editNewVersion/${item.gameVersionId}`)}
            >
              <AiFillEdit />
              Edit
            </button>
          </td>
        </tr>
      );
    });

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="list-time-bank pb-5">
          <Col>
            <BreadCrumbs childItem="List Versions" />
            <div className="card m-auto">
              <div className="portlet-title">
                <h3 className="portlet-title__text ">List Versions</h3>
              </div>

              <div className="table-data overflow-scroll">
                <table className="table table-bordered mt-5">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Device Type</th>
                      <th>Status</th>
                      <th>Maintenance Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{displayData}</tbody>
                </table>
              </div>
            </div>
          </Col>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPage}
            onPageChange={handlePageChange}
            activeClassName={"page-item active"}
            disabledClassName={"page-item disabled"}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
          />
        </div>
      )}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Do you want to delete ?</Modal.Body>
        <Modal.Footer>
          <button className="btn-custom btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn-custom btn-danger" onClick={handleDelete}>
            Remove
          </button>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
};
export default ListVersions;
